import React from 'react'
import propTypes from 'prop-types'
import Expander from '@/component/Primitive/Expander'
import Prose from '@/component/Primitive/Prose'
import Type from '@/component/Primitive/Type'
import { useDarkContext } from '@/component/Context/DarkContext'

import styles from './Faq.module.scss'
import classNames from 'classnames'
import { Accordion } from '@/component/BBFC/Release/components/Accordion/Accordion'

const FaqWidgetWrap = ({ designV2, children, padding }) => {
  if (designV2 || padding) {
    return (
      <div
        className={classNames(
          designV2 && 'borderRadius--V2',
          padding && 'vertical-padding--section horizontal-padding--section'
        )}
      >
        {children}
      </div>
    )
  }
  return <div className={styles.FaqWrapper}>{children}</div>
}

const Faq = (props) => {
  const { faqs, title, description, designV2, padding } = props
  const isDark = useDarkContext()
  return (
    <FaqWidgetWrap designV2={designV2} padding={padding}>
      <div
        className={classNames(
          isDark && styles.isDark,
          designV2 && styles.designV2
        )}
      >
        <div className={styles.FaqTitle}>
          {title && (
            <Type
              className={styles.FaqTitle}
              size="display1"
              color="teal"
              as="span"
            >
              {title}
            </Type>
          )}
          {description && (
            <Prose className={styles.Content}>{description}</Prose>
          )}
        </div>
        {faqs &&
          faqs.map((faq, i) => {
            if (!faq) return null
            if (designV2) {
              return (
                <Accordion
                  options={{ title: styles.FaqsTitle }}
                  title={faq.question}
                >
                  <Prose className={styles.FaqContent} html={faq.answer} />
                </Accordion>
              )
            }
            return (
              <Expander
                options={{ spacious: true }}
                title={faq.question}
                key={i}
              >
                <Prose className={styles.FaqContent} html={faq.answer} />
              </Expander>
            )
          })}
      </div>
    </FaqWidgetWrap>
  )
}
FaqWidgetWrap.propTypes = {
  designV2: propTypes.bool,
  children: propTypes.node,
  padding: propTypes.bool
}
Faq.propTypes = {
  faqs: propTypes.array,
  title: propTypes.string,
  description: propTypes.string,
  designV2: propTypes.bool,
  padding: propTypes.bool
}

export default Faq
