import React from 'react'
import PropTypes from 'prop-types'
import styles from './FullImageItem.module.scss'
import classNames from 'classnames'

const FullImageItem = ({ image, subHeading, title, url }) => {
  const backgroundImage =
    image.xlDesktopImage || image.lgDesktopImage || image.desktopImage

  const mobileBackgroundImage = image.mobileImage
  return (
    <a
      href={url}
      className={classNames(styles.Container)}
      //
    >
      <div
        className={classNames(
          styles.BackgroundImage,
          styles.MobileBackgroundImage
        )}
        style={{ backgroundImage: `url(${mobileBackgroundImage.url})` }}
      />
      <div
        className={classNames(
          styles.BackgroundImage,
          styles.DesktopBackgroundImage
        )}
        style={{ backgroundImage: `url(${backgroundImage.url})` }}
      />
      <span role="img" aria-label={backgroundImage.alt || title} />

      <FullImageContent subHeading={subHeading} title={title} />
    </a>
  )
}
const FullImageContent = ({ subHeading, title }) => {
  return (
    <div className={styles.ContentWrap}>
      <div className={styles.Content}>
        {subHeading && <h3 className={styles.SubHeading}>{subHeading}</h3>}
        <div
          className={styles.Title}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </div>
    </div>
  )
}
FullImageContent.propTypes = {
  subHeading: PropTypes.string,
  title: PropTypes.string
}
FullImageItem.propTypes = {
  url: PropTypes.string,
  subHeading: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.shape({
    desktopImage: PropTypes.shape({
      url: PropTypes.string
    }),
    lgDesktopImage: PropTypes.shape({
      url: PropTypes.string
    }),
    mobileImage: PropTypes.shape({
      url: PropTypes.string
    }),
    xlDesktopImage: PropTypes.shape({
      url: PropTypes.string
    })
  })
}
export default FullImageItem
