import React from 'react'
import styles from './BBFCArticleHeaderV2.module.scss'
import Icon from '@/component/Primitive/Icon'
import { object, array, shape, string } from 'prop-types'

const BBFCArticleHeaderV2 = (props) => {
  const { headline, contentRating, images, articleType } = props.article
  const isRateATrailerType = articleType === 'rateATrailer'
  const heroImage = images && images.hero_5x2_1920 && images.hero_5x2_1920[0]

  if (!heroImage) {
    console.warn('BBFCArticleHeaderV2: Missing hero image')
  }

  const formattedRating = contentRating ? contentRating.toLowerCase() : ''

  const shortenHeadline = headline.slice(0, 16) + '...'
  return (
    <div
      className={styles.Container}
      style={{ backgroundImage: heroImage ? `url(${heroImage.url})` : null }}
    >
      <span role="img" aria-label={heroImage.alt || headline} />
      <div className={styles.NavContentWrap}>
        <div>
          <a className={styles.NavLink} href="/">
            Home
          </a>
          <span> / </span>
          {props.sections.map((item, index) => (
            <>
              <a className={styles.NavLink} href={item.fullUrlPath} key={index}>
                {item.name}
              </a>
              <span> /</span>
            </>
          ))}
          <span>
            <strong className={styles.DesktopLink}> {headline}</strong>
            <strong className={styles.MobileLink}> {shortenHeadline}</strong>
          </span>
        </div>
      </div>
      <div className={styles.ContentWrap}>
        <div className={styles.Content}>
          {!isRateATrailerType && formattedRating && (
            <div className={styles.RatingIconWrap}>
              <Icon
                className={styles.RatingIcon}
                type={`rating-${formattedRating}`}
                a11yText={`rating-${formattedRating}`}
              />
            </div>
          )}
          <h1 className={styles.Headline}>{headline}</h1>
        </div>
      </div>
    </div>
  )
}

BBFCArticleHeaderV2.propTypes = {
  article: shape({
    headline: string,
    contentRating: string,
    images: object
  }),
  sections: array
}
export default BBFCArticleHeaderV2
